import { EventEmitter, Injectable, Output } from '@angular/core';
import { DataService, Technique, Tactic, Matrix, Domain, VersionChangelog } from "./data.service";
import * as tinygradient from 'tinygradient';
import * as tinycolor from 'tinycolor2';
import { evaluate } from 'mathjs';
import * as globals from './globals'; //global variables
import * as is from 'is_js';

@Injectable({
  providedIn: 'root'
})
export class ViewModelsService {
    @Output() onSelectionChange = new EventEmitter<any>();
    pinnedCell = "";

    constructor(private dataService: DataService) { }

    viewModels: ViewModel[] = [];

    /**
     * Emit event when technique selection changes
     */
    selectionChanged() {
        this.onSelectionChange.emit();
    }

    /**
     * Create and return a new viewModel
     * @param {string} name the viewmodel name
     * @param {string} domainVersionID the ID of the domain & version
     * @return {ViewModel} the created ViewModel
     */
    newViewModel(name: string, domainVersionID: string) {
        let vm = new ViewModel(name, "vm"+ this.getNonce(), domainVersionID, this.dataService);
        this.viewModels.push(vm);
        return vm;
    }

    nonce: number = 0;
    /**
     * Get a nonce.
     * @return a number that will never be regenerated by sequential calls to getNonce.
     *         Note: this applies on a session-by-session basis, nonces are not
     *         unique between app instances.
     */
    getNonce(): number {
        return this.nonce++;
    }

    /**
     * Destroy the viewmodel completely Nessecary if tab is closed!
     * @param vm viewmodel to destroy.
     */
    destroyViewModel(vm: ViewModel): void {
        for (let i = 0; i < this.viewModels.length; i++) {
            if (this.viewModels[i] == vm) {
                // console.log("destroying viewmodel", vm)
                this.viewModels.splice(i,1)
                return;
            }
        }
    }

    /**
     * layer combination operation
     * @param  domainVersionID  domain & version ID
     * @param  scoreExpression  math expression of score expression
     * @param  scoreVariables   variables in math expression, mapping to viewmodel they correspond to
     * @param  comments         what viewmodel to inherit comments from
     * @param  links            what viewmodel to inherit links from
     * @param  metadata         what viewmodel to inherit technique metadata from
     * @param  coloring         what viewmodel to inherit manual colors from
     * @param  enabledness      what viewmodel to inherit state from
     * @param  layerName        new layer name
     * @param  filters          viewmodel to inherit filters from
     * @return                  new viewmodel inheriting above properties
     */
    layerLayerOperation(domainVersionID: string, scoreExpression: string, scoreVariables: Map<string, ViewModel>, comments: ViewModel, links: ViewModel, metadata: ViewModel, gradient: ViewModel, coloring: ViewModel, enabledness: ViewModel, layerName: string, filters: ViewModel, legendItems: ViewModel): ViewModel {
        let result = new ViewModel("layer by operation", "vm" + this.getNonce(), domainVersionID, this.dataService);

        if (scoreExpression) {
            scoreExpression = scoreExpression.toLowerCase() //should be enforced by input, but just in case
            let score_min = Infinity;
            let score_max = -Infinity;

            //get list of all technique IDs used in the VMs
            let techniqueIDs = new Set<string>();
            scoreVariables.forEach(function(vm, key) {
                vm.techniqueVMs.forEach(function(techniqueVM, techniqueID) {
                    techniqueIDs.add(techniqueID);
                })
            })
            //attempt to evaluate without a scope to catch the case of a static assignment
            try {
                // evaluate with an empty scope
                let mathResult = evaluate(scoreExpression, {});
                // if it didn't except after this, it evaluated to a single result.
                console.log("score expression evaluated to single result to be applied to all techniques");
                if (is.boolean(mathResult)) {
                    mathResult = mathResult ? "1" : "0"; //boolean to binary
                } else if (is.not.number(mathResult)) { //user inputted something weird, complain about it
                    throw {message: "math result ( " + mathResult + " ) is not a number"};
                }
                // if it didn't error, and outputted a single value, apply this to all techniques.
                result.initializeScoresTo = String(mathResult); //initialize scores to this value
                score_min = mathResult;
                score_max = mathResult;
            } catch(err) { //couldn't evaluate with empty scope, build scope for each technique
                // compute the score of each techniqueID
                techniqueIDs.forEach(function(technique_id) {
                    let new_tvm = new TechniqueVM(technique_id);
                    let scope = {};
                    let misses = 0; //number of times a VM is missing the value
                    scoreVariables.forEach(function(vm, key) {
                        let scoreValue: number;
                        if (!vm.hasTechniqueVM_id(technique_id)) { //missing technique
                            scoreValue = 0;
                            misses++;
                        } else { //technique exists
                            let score = vm.getTechniqueVM_id(technique_id).score;
                            if (score == "") {
                                scoreValue = 0;
                                misses++;
                            } else if (isNaN(Number(score))) {
                                scoreValue = 0;
                                misses++;
                            } else {
                                scoreValue = Number(score);
                            }
                        }
                        scope[key] = scoreValue;
                    });
                    //don't record a result if none of VMs had a score for this technique
                    //did at least one technique have a score for this technique?
                    if (misses < scoreVariables.size) {
                        let mathResult = evaluate(scoreExpression, scope);
                        if (is.boolean(mathResult)) {
                            mathResult = mathResult ? "1" : "0"; //boolean to binary
                        } else if (is.not.number(mathResult)) { //user inputted something weird, complain about it
                            throw {message: "math result ( " + mathResult + " ) is not a number"};
                        }
                        new_tvm.score = String(mathResult);
                        result.techniqueVMs.set(technique_id, new_tvm);

                        score_min = Math.min(score_min, mathResult);
                        score_max = Math.max(score_max, mathResult);
                    }
                })
            }
            //don't do gradient if there's no range of values
            if (score_min != score_max) {
                // set up gradient according to result range
                if (score_min != Infinity) result.gradient.minValue = score_min;
                if (score_max != -Infinity) result.gradient.maxValue = score_max;
                // if it's a binary range, set to transparentblue gradient
                if (score_min == 0 && score_max == 1) result.gradient.setGradientPreset("transparentblue");
            }
        }


        /**
         * Inherit a field from a vm
         * @param  {ViewModel} inherit_vm the viewModel to inherit from
         * @param  {string}    fieldname  the field to inherit from the viewmodel
         */
        function inherit(inherit_vm: ViewModel, fieldname: string) {
            // console.log("inherit", fieldname)
            inherit_vm.techniqueVMs.forEach(function(inherit_TVM) {
                let tvm = result.hasTechniqueVM_id(inherit_TVM.technique_tactic_union_id) ? result.getTechniqueVM_id(inherit_TVM.technique_tactic_union_id) : new TechniqueVM(inherit_TVM.technique_tactic_union_id)
                tvm[fieldname] = inherit_TVM[fieldname];
                result.techniqueVMs.set(inherit_TVM.technique_tactic_union_id, tvm);
            })
        }

        if (comments) inherit(comments, "comment");
        if (links) inherit(links, "links");
        if (metadata) inherit(metadata, "metadata");
        if (coloring) inherit(coloring, "color");
        if (enabledness) inherit(enabledness, "enabled");

        if (filters) { //copy filter settings
            result.filters.deSerialize(JSON.parse(filters.filters.serialize()))
        }

        if (legendItems) {
            result.legendItems = JSON.parse(JSON.stringify(legendItems.legendItems));
        }

        if (gradient) {
            result.gradient = new Gradient();
            result.gradient.deSerialize(gradient.gradient.serialize());
        }

        result.name = layerName;
        // console.log(result)
        this.viewModels.push(result)
        result.updateGradient();
        return result;
    } //end layer layer operation
}



/**
 * Gradient class used by viewmodels
 */
export class Gradient {
    //official colors used in gradients:

    colors: Gcolor[] = [new Gcolor("red"), new Gcolor("green")]; //current colors
    // options: string[] = ["white", "red", "orange", "yellow", "green", "blue", "purple"]; //possible colors
    options: string[] = ["#ffffff", "#ff6666", "#ffaf66","#ffe766", "#8ec843", "#66b1ff", "#ff66f4"]; //possible colors
    minValue: number = 0;
    maxValue: number = 100;
    gradient: any;
    gradientRGB: any;

    /**
     * Create a string version of this gradient
     * @return string version of gradient
     */
    serialize(): string {
        let colorList: string[] = [];
        let self = this;
        this.colors.forEach(function(gColor: Gcolor) {
            let hexstring = (tinycolor(gColor.color).toHex8String()); // include the alpha channel
            colorList.push(hexstring)
        });

        let rep = {
                "colors": colorList,
                "minValue": this.minValue,
                "maxValue": this.maxValue,
              }
        return JSON.stringify(rep, null, "\t")
    }

    /**
     * Restore this gradient from the given serialized representation
     * @param  rep serialized gradient
     */
    deSerialize(rep: string): void {
        let obj = JSON.parse(rep)
        let isColorStringArray = function(check): boolean {
            for (let i = 0; i < check.length; i++) {
                if (typeof(check[i]) !== "string" || !tinycolor(check[i]).isValid()) {
                    console.error("TypeError:", check[i], "(",typeof(check[i]),")", "is not a color-string")
                    return false;
                }
            }
            return true;
        }


        if (isColorStringArray(obj.colors)) {
            this.colors = []
            let self = this;
            obj.colors.forEach(function(hex: string) {
                self.colors.push(new Gcolor(hex));
            });
        } else console.error("TypeError: gradient colors field is not a color-string[]")
        this.minValue = obj.minValue;
        this.maxValue = obj.maxValue;
        this.updateGradient();
    }

    //presets in dropdown menu
    presets = {
        redgreen: [new Gcolor("#ff6666"), new Gcolor("#ffe766"), new Gcolor("#8ec843")],
        greenred: [new Gcolor("#8ec843"), new Gcolor("#ffe766"), new Gcolor("#ff6666")],
        bluered: [new Gcolor("#66b1ff"), new Gcolor("#ff66f4"), new Gcolor("#ff6666")],
        redblue: [new Gcolor("#ff6666"), new Gcolor("#ff66f4"), new Gcolor("#66b1ff")],
        transparentblue: [new Gcolor("#ffffff00"), new Gcolor("#66b1ff")],
        transparentred: [new Gcolor("#ffffff00"), new Gcolor("#ff6666")]
    }

    /**
     * Convert a preset to tinycolor array
     * @param  preset preset name from preset array
     * @return        [description]
     */
    presetToTinyColor(preset) {
        let colorarray = []
        let self = this;
        this.presets[preset].forEach(function(gcolor: Gcolor) {
            colorarray.push(gcolor.color);
        });
        return tinygradient(colorarray).css('linear', 'to right');
    }

    constructor() { this.setGradientPreset('redgreen'); }

    /**
     * set this gradient to use the preset
     * @param  preset preset to use
     */
    setGradientPreset(preset: string): void {
        this.colors = this.presets[preset].map((color: Gcolor) => new Gcolor(color.color)); //deep copy gradient preset
        this.updateGradient();
    }

    /**
     * recompute gradient
     */
    updateGradient(): void {
        let colorarray = [];
        let self = this;
        this.colors.forEach(function(colorobj) {
            // figure out what kind of color this is
            // let format = tinycolor(colorobj.color).getFormat();
            // if (format == "name" && colorobj.color in self.labelToColor)
            colorarray.push(colorobj.color)
        });
        this.gradient = tinygradient(colorarray);
        this.gradientRGB = this.gradient.rgb(100);
    }

    /**
     * Add a color to the end of the gradient
     */
    addColor(): void {
        this.colors.push(new Gcolor(this.colors[this.colors.length - 1].color));
    }

    /**
     * Remove color at the given index
     * @param index index to remove color at
     */
    removeColor(index): void {
        this.colors.splice(index, 1)
    }

    // get the gradient color for a given value in the scale. Value is string format number
    getColor(valueString: string) {
        if (!this.gradient) this.updateGradient();

        let value: number;
        if (valueString.length == 0) return;
        else value = Number(valueString);

        if (value >= this.maxValue) { return this.gradientRGB[this.gradientRGB.length - 1]; }
        if (value <= this.minValue) { return this.gradientRGB[0]; }
        let index = (value - this.minValue)/(this.maxValue - this.minValue) * 100;
        // console.log(value, "->", index)
        return this.gradientRGB[Math.round(index)];
    }
}
//a color in the gradient
export class Gcolor {color: string; constructor(color: string) {this.color = color}};

//semi-synonymous with "layer"
export class ViewModel {
    // PROPERTIES & DEFAULTS

    name: string; // layer name
    domain: string = ""; // attack domain
    version: string = ""; // attack version
    domainVersionID: string; // layer domain & version
    description: string = ""; //layer description
    uid: string; //unique identifier for this ViewModel. Do not serialize, let it get initialized by the VmService
    bundleURL: string; // the STIX bundle URL that a custom layer was loaded from
    loaded: boolean = false; // whether or not techniqueVMs are loaded

    filters: Filter;

    metadata: Metadata[] = [];
    links: Link[] = [];

    /*
     * sorting int meanings (see filterTechniques()):
     * 0: ascending alphabetically
     * 1: descending alphabetically
     * 2: ascending numerically
     * 3: descending numerically
     */
    sorting: number = 0;

    layout: LayoutOptions = new LayoutOptions();


    hideDisabled: boolean = false; //are disabled techniques hidden?


    gradient: Gradient = new Gradient(); //gradient for scores

    backgroundPresets: string[] = ['#e60d0d', '#fc3b3b', '#fc6b6b', '#fca2a2', '#e6550d', '#fd8d3c', '#fdae6b', '#fdd0a2', '#e6d60d', '#fce93b', '#fcf26b', '#fcf3a2', '#31a354', '#74c476', '#a1d99b', '#c7e9c0', '#3182bd', '#6baed6', '#9ecae1', '#c6dbef', '#756bb1', '#9e9ac8', '#bcbddc', '#dadaeb', '#636363', '#969696', '#bdbdbd', '#d9d9d9'];
    legendColorPresets: string[] = [];

    selectTechniquesAcrossTactics: boolean = true;
    selectSubtechniquesWithParent: boolean = false;

    needsToConstructTechniqueVMs = false;
    legacyTechniques = [];

    initializeScoresTo = ""; //value to initialize scores to

    techIDtoUIDMap: Object = {};
    techUIDtoIDMap: Object = {};

    compareTo?: ViewModel;
    versionChangelog?: VersionChangelog;

    private _sidebarOpened: boolean;
    public get sidebarOpened(): boolean { return this._sidebarOpened; };
    public set sidebarOpened(newVal: boolean) { this._sidebarOpened = newVal; };

    public readonly sidebarContentTypes = ['layerUpgrade', 'search'];
    private _sidebarContentType: string;
    public get sidebarContentType(): string { return this._sidebarContentType; };
    public set sidebarContentType(newVal: string) {
        if (this.sidebarContentTypes.includes(newVal)) this._sidebarContentType = newVal;
        else this._sidebarContentType = '';
    };

    constructor(name: string, uid: string, domainVersionID: string, private dataService: DataService) {
        this.domainVersionID = domainVersionID;
        console.log("initializing ViewModel '" + name + "'");
        this.filters = new Filter();
        this.name = name;
        this.uid = uid;
        this.legendColorPresets = this.backgroundPresets;
    }

    loadVMData() {
        let domain = this.dataService.getDomain(this.domainVersionID);
        if (domain.isCustom) {
            this.bundleURL = domain.urls[0];
        }

        if (!this.domainVersionID || !domain.dataLoaded) {
            let self = this;
            this.dataService.onDataLoad(this.domainVersionID, function() {
                self.initTechniqueVMs()
                self.filters.initPlatformOptions(self.dataService.getDomain(self.domainVersionID));
            });
        } else {
            this.initTechniqueVMs();
            this.filters.initPlatformOptions(domain);
        }
        this.loaded = true;
    }

    initTechniqueVMs() {
        console.log(this.name, "initializing technique VMs");
        for (let technique of this.dataService.getDomain(this.domainVersionID).techniques) {
            for (let id of technique.get_all_technique_tactic_ids()) {
                let techniqueVM = new TechniqueVM(id);
                techniqueVM.score = this.initializeScoresTo;
                this.setTechniqueVM(techniqueVM, false);
            }
            //init subtechniques
            for (let subtechnique of technique.subtechniques) {
                for (let id of subtechnique.get_all_technique_tactic_ids()) {
                    let techniqueVM = new TechniqueVM(id);
                    techniqueVM.score = this.initializeScoresTo;
                    this.setTechniqueVM(techniqueVM, false);
                }
            }
        }
    }

    // changeTechniqueIDSelectionLock() {
    //     this.selectTechniquesAcrossTactics = !this.selectTechniquesAcrossTactics;
    // }

    showTacticRowBackground: boolean = false;
    tacticRowBackground: string = "#dddddd";

    // getTechniqueIDFromUID(technique_tactic_union_id: string){
    //     return this.techIDtoUIDMap[technique_tactic_union_id];
    // }

    // getTechniquesUIDFromID(technique_id: string){
    //     return this.techIDtoUIDMap[technique_id];
    // }

    // setTechniqueMaps(techIDtoUIDMapt, techUIDtoIDMapt){
    //     this.techIDtoUIDMap = Object.freeze(techIDtoUIDMapt);
    //     this.techUIDtoIDMap = Object.freeze(techUIDtoIDMapt);
    // }

     //  _____ ___ ___ _  _ _  _ ___ ___  _   _ ___     _   ___ ___
     // |_   _| __/ __| || | \| |_ _/ _ \| | | | __|   /_\ | _ \_ _|
     //   | | | _| (__| __ | .` || | (_) | |_| | _|   / _ \|  _/| |
     //   |_| |___\___|_||_|_|\_|___\__\_\\___/|___| /_/ \_\_| |___|

    techniqueVMs: Map<string, TechniqueVM> = new Map<string, TechniqueVM>(); //configuration for each technique
    // Getter
    public getTechniqueVM(technique: Technique, tactic: Tactic): TechniqueVM {
        if (!this.hasTechniqueVM(technique, tactic)) throw Error("technique VM not found: " + technique.attackID + ", " + tactic.attackID);
        return this.techniqueVMs.get(technique.get_technique_tactic_id(tactic));
    }
    public getTechniqueVM_id(technique_tactic_id: string): TechniqueVM {
        if (!this.hasTechniqueVM_id(technique_tactic_id)) throw Error("technique VM not found: " + technique_tactic_id);
        return this.techniqueVMs.get(technique_tactic_id);
    }
    /**
     * setter
     * @param {techniqueVM} techniqueVM: the techniqueVM to set
     * @param {boolean} overwrite (default true) if true, overwrite existing techniqueVMs under that ID.
     */
    public setTechniqueVM(techniqueVM: TechniqueVM, overwrite=true): void {
        if (this.techniqueVMs.has(techniqueVM.technique_tactic_union_id)) {
            if (overwrite) this.techniqueVMs.delete(techniqueVM.technique_tactic_union_id)
            else return;
        }
        this.techniqueVMs.set(techniqueVM.technique_tactic_union_id, techniqueVM);
    }
    //checker
    public hasTechniqueVM(technique: Technique, tactic: Tactic): boolean {
        return this.techniqueVMs.has(technique.get_technique_tactic_id(tactic));
    }
    public hasTechniqueVM_id(technique_tactic_id: string): boolean {
        return this.techniqueVMs.has(technique_tactic_id);
    }

    //  ___ ___ ___ _____ ___ _  _  ___     _   ___ ___
    // | __|   \_ _|_   _|_ _| \| |/ __|   /_\ | _ \_ _|
    // | _|| |) | |  | |  | || .` | (_ |  / _ \|  _/| |
    // |___|___/___| |_| |___|_|\_|\___| /_/ \_\_| |___|


    public highlightedTactic: Tactic = null;
    public highlightedTechniques: Set<string> = new Set<string>();
    public highlightedTechnique: Technique = null; // the Technique that was actually moused over

    /**
     * Highlight the given technique under the given tactic
     * @param {Technique} technique to highlight
     * @param {Tactic} tactic wherein the technique occurs
     */
    public highlightTechnique(technique: Technique, tactic?: Tactic | null) {
        if (this.selectSubtechniquesWithParent && technique.isSubtechnique) this.highlightedTechniques.add(technique.parent.id);
        this.highlightedTechnique = technique;
        this.highlightedTechniques.add(technique.id);
        this.highlightedTactic = tactic;
    }
    /**
     * Clear the technique highlight
     */
    public clearHighlight() {
        this.highlightedTactic = null;
        this.highlightedTechnique = null;
        this.highlightedTechniques = new Set<string>();
    }

    /**
     * currently selected techniques in technique_tactic_id format
     */
    private selectedTechniques: Set<string> = new Set<string>();

    /**
     * Select the given technique. Depending on selectTechniquesAcrossTactics, either selects in all tactics or in given tactic
     * @param {Technique} technique to select
     * @param {Tactic} tactic wherein the technique occurs
     */
    public selectTechnique(technique: Technique, tactic: Tactic): void {
        if (this.selectTechniquesAcrossTactics) this.selectTechniqueAcrossTactics(technique);
        else (this.selectTechniqueInTactic(technique, tactic));
    }

    /**
     * unselect the given technique. Depending on selectTechniquesAcrossTactics, either unselects in all tactics or in given tactic
     * @param {Technique} technique to select
     * @param {Tactic} tactic wherein the technique occurs
     */
    public unselectTechnique(technique: Technique, tactic: Tactic): void {
        if (this.selectTechniquesAcrossTactics) this.unselectTechniqueAcrossTactics(technique);
        else (this.unselectTechniqueInTactic(technique, tactic));
    }

    /**
     * select the given technique in the given tactic
     * @param {Technique} technique to select
     * @param {Tactic} tactic wherein the technique occurs
     * @param {boolean} walkChildren (recursion helper) if true and selectSubtechniquesWithParent is true, walk selection up to parent technique
     */
    public selectTechniqueInTactic(technique: Technique, tactic: Tactic, walkChildren=true): void {
        if (this.selectSubtechniquesWithParent && walkChildren) { //check parent / children / siblings
            if (technique.isSubtechnique) { //select from parent
                this.selectTechniqueInTactic(technique.parent, tactic, true);
                return;
            } else { //select subtechniques
                for (let subtechnique of technique.subtechniques) {
                    this.selectTechniqueInTactic(subtechnique, tactic, false);
                }
            }
        }
        let technique_tactic_id = technique.get_technique_tactic_id(tactic);
        if (!this.isCurrentlyEditing()) this.activeTvm = this.getTechniqueVM_id(technique_tactic_id); // first selection
        this.selectedTechniques.add(technique_tactic_id);
        this.checkValues(true, technique_tactic_id);
    }

    /**
     * select all techniques under the given tactic
     * @param {Tactic} tactic wherein the techniques occur
     */
    public selectAllTechniquesInTactic(tactic: Tactic): void {
        for (let technique of tactic.techniques) {
            this.selectTechnique(technique, tactic);
        }
    }

    /**
     * select the given technique across all tactics in which it occurs
     * @param {Technique} technique to select
     * @param {boolean} walkChildren (recursion helper) if true and selectSubtechniquesWithParent is true, walk selection up to parent technique
     * @param highlightTechniques, if true, highlight techniques rather than add to selected techniques group
     */
    public selectTechniqueAcrossTactics(technique: Technique, walkChildren= true, highlightTechniques = false): void {
        if (this.selectSubtechniquesWithParent && walkChildren) { //walk to parent / children / siblings
            if (technique.isSubtechnique) { //select from parent
                this.selectTechniqueAcrossTactics(technique.parent, true, highlightTechniques);
                return;
            } else { //select subtechniques
                for (let subtechnique of technique.subtechniques) {
                    this.selectTechniqueAcrossTactics(subtechnique, false, highlightTechniques);
                }
            }
        }
        if (highlightTechniques) {
            this.highlightTechnique(technique);
        }
        else {
            for (let id of technique.get_all_technique_tactic_ids()) {
                if (!this.isCurrentlyEditing()) this.activeTvm = this.getTechniqueVM_id(id); // first selection
                this.selectedTechniques.add(id);
                this.checkValues(true, id);
            }
        }
    }

    /**
     * unselect the given technique in the given tactic
     * @param {Technique} technique to unselect
     * @param {Tactic} tactic wherein the technique occurs
     * @param {boolean} walkChildren (recursion helper) if true and selectSubtechniquesWithParent is true, walk selection up to parent technique
     */
    public unselectTechniqueInTactic(technique: Technique, tactic: Tactic, walkChildren=true): void {
        if (this.selectSubtechniquesWithParent && walkChildren) { //walk to parent / children / siblings
            if (technique.isSubtechnique) { //select from parent
                this.unselectTechniqueInTactic(technique.parent, tactic, true);
                return;
            } else { //select subtechniques
                for (let subtechnique of technique.subtechniques) {
                    this.unselectTechniqueInTactic(subtechnique, tactic, false);
                }
            }
        }
        let technique_tactic_id = technique.get_technique_tactic_id(tactic);
        this.selectedTechniques.delete(technique_tactic_id);
        this.checkValues(false, technique_tactic_id);
    }

    /**
     * unselect all techniques in the given tactic
     * @param {Tactic} tactic wherein the techniques occur
     */
    public unselectAllTechniquesInTactic(tactic: Tactic): void {
        for (let technique of tactic.techniques) {
            this.unselectTechnique(technique, tactic);
        }
    }

    /**
     * unselect the given technique across all tactics in which it occurs
     * @param {Technique} technique to unselect
     * @param {boolean} walkChildren (recursion helper) if true and selectSubtechniquesWithParent is true, walk selection up to parent technique
     */
    public unselectTechniqueAcrossTactics(technique: Technique, walkChildren=true) {
        if (this.selectSubtechniquesWithParent && walkChildren) { //walk to parent / children / siblings
            if (technique.isSubtechnique) { //select from parent
                this.unselectTechniqueAcrossTactics(technique.parent, true);
                return;
            } else { //select subtechniques
                for (let subtechnique of technique.subtechniques) {
                    this.unselectTechniqueAcrossTactics(subtechnique, false);
                }
            }
        }
        for (let id of technique.get_all_technique_tactic_ids()) {
            this.selectedTechniques.delete(id);
            this.checkValues(false, id);
        }
    }

    /**
     * unselect all techniques
     */
    public clearSelectedTechniques() {
        this.selectedTechniques.clear();
        this.activeTvm = undefined;
        this.linkMismatches = [];
        this.metadataMismatches = [];
    }

    /**
     * Select all techniques
     */
    public selectAllTechniques(): void {
        this.clearSelectedTechniques()
        this.invertSelection();
    }

    /**
     * Set all selected techniques to deselected, and select all techniques not currently selected
     */
    public invertSelection(): void {
        let previouslySelected = new Set(this.selectedTechniques);
        this.clearSelectedTechniques();
        let self = this;
        this.techniqueVMs.forEach(function(tvm, key) {
            if (!previouslySelected.has(tvm.technique_tactic_union_id)) {
                if (!self.isCurrentlyEditing()) self.activeTvm = self.getTechniqueVM_id(tvm.technique_tactic_union_id); // first selection
                self.selectedTechniques.add(tvm.technique_tactic_union_id);
                self.checkValues(true, tvm.technique_tactic_union_id);
            }
        });
    }

    /**
     * Select all techniques with annotations if nothing is currently selected, or select a subset of
     * the current selection that has annotations
     */
    public selectAnnotated(): void {
        let self = this;
        if (this.isCurrentlyEditing()) {
            // deselect techniques without annotations
            let selected = new Set(this.selectedTechniques);
            this.techniqueVMs.forEach(function(tvm, key) {
                if (selected.has(tvm.technique_tactic_union_id) && !tvm.annotated()) {
                    self.selectedTechniques.delete(tvm.technique_tactic_union_id);
                    self.checkValues(false, tvm.technique_tactic_union_id);
                }
            });
        } else {
            // select all techniques with annotations
            this.techniqueVMs.forEach(function(tvm, key) {
                if (tvm.annotated()) {
                    if (!self.isCurrentlyEditing()) self.activeTvm = self.getTechniqueVM_id(tvm.technique_tactic_union_id); // first selection
                    self.selectedTechniques.add(tvm.technique_tactic_union_id);
                    self.checkValues(true, tvm.technique_tactic_union_id);
                }
            });
        }
    }

    /**
     * Select all techniques without annotations if nothing is currently selected, or select a subset of
     * the current selection that do not have annotations
     */
    public selectUnannotated(): void {
        let self = this;
        if (this.isCurrentlyEditing()) {
            // deselect techniques with annotations
            let selected = new Set(this.selectedTechniques);
            this.techniqueVMs.forEach(function(tvm, key) {
                if (selected.has(tvm.technique_tactic_union_id) && tvm.annotated()) {
                    self.selectedTechniques.delete(tvm.technique_tactic_union_id);
                    self.checkValues(false, tvm.technique_tactic_union_id);
                }
            });
        } else {
            // select all techniques without annotations
            this.selectAnnotated();
            this.invertSelection();
        }
    }

    /**
     * Copies all annotations from unchanged techniques and techniques
     * which have had minor changes
     */
    public initCopyAnnotations(): void {
        let self = this;

        function copy(attackID: string) {
            let fromTechnique = self.dataService.getTechnique(attackID, self.compareTo.domainVersionID);
            let domain = self.dataService.getDomain(self.domainVersionID);
            let tactics = fromTechnique.tactics.map(shortname => domain.tactics.find(t => t.shortname == shortname));
            tactics.forEach(tactic => {
                let fromVM = self.compareTo.getTechniqueVM(fromTechnique, tactic);
                if (fromVM.annotated()) {
                    let toTechnique = self.dataService.getTechnique(attackID, self.domainVersionID);
                    self.copyAnnotations(fromTechnique, toTechnique, tactic);
                }
            });
        }

        if (this.versionChangelog) {
            this.versionChangelog.unchanged.forEach(attackID => copy(attackID));
            this.versionChangelog.minor_changes.forEach(attackID => copy(attackID));
        }
    }

    /**
     * Copy annotations from one technique to another under the given tactic.
     * The previous technique will be disabled
     * @param fromTechnique the technique to copy annotations from
     * @param toTechnique the technique to copy annotations to
     * @param tactic the tactic the techniques are found under
     */
    public copyAnnotations(fromTechnique: Technique, toTechnique: Technique, tactic: Tactic): void {
        let fromVM = this.compareTo.getTechniqueVM(fromTechnique, tactic);
        let toVM = this.getTechniqueVM(toTechnique, tactic);

        this.versionChangelog.reviewed.delete(fromTechnique.attackID);

        toVM.deSerialize(fromVM.serialize(), fromTechnique.attackID, tactic.shortname);
        this.updateScoreColor(toVM);
        fromVM.enabled = false;

        this.versionChangelog.copied.add(fromVM.technique_tactic_union_id);
        if (fromTechnique.get_all_technique_tactic_ids().every(id => this.versionChangelog.copied.has(id))) {
            this.versionChangelog.reviewed.add(fromTechnique.attackID);
        }
    }

    /**
     * Reset the techniqueVM that the annotations were previously copied to
     * and re-enable the technique the annotations were copied from
     * @param fromTechnique the technique that annotations were copied from
     * @param toTechnique the technique that annotations were copied to
     * @param tactic the tactic the techniques are found under
     */
    public revertCopy(fromTechnique: Technique, toTechnique: Technique, tactic: Tactic): void {
        let fromVM = this.compareTo.getTechniqueVM(fromTechnique, tactic);
        let toVM = this.getTechniqueVM(toTechnique, tactic);
        this.versionChangelog.reviewed.delete(fromTechnique.attackID);

        toVM.resetAnnotations();
        fromVM.enabled = true;

        this.versionChangelog.copied.delete(fromVM.technique_tactic_union_id);
        if (!fromTechnique.get_all_technique_tactic_ids().every(id => this.versionChangelog.copied.has(id))) {
            this.versionChangelog.reviewed.delete(fromTechnique.attackID);
        }
    }

    /**
     * Return true if the given technique is selected, false otherwise
     * @param  {Technique}  technique the technique to check
    * * @param  {Tactic}  tactic wherein the technique occurs
     * @return {boolean}           true if selected, false otherwise
     */
    public isTechniqueSelected(technique: Technique, tactic: Tactic, walkChildren=true): boolean {
        if (this.selectTechniquesAcrossTactics) {
            if (this.selectSubtechniquesWithParent && walkChildren) { //check parent / children / siblings
                if (technique.isSubtechnique) { //select from parent
                    return this.isTechniqueSelected(technique.parent, tactic, true);
                } else {
                    for (let subtechnique of technique.subtechniques) {
                        if (this.isTechniqueSelected(subtechnique, tactic, false)) return true;
                    }
                }
            }

            for (let id of technique.get_all_technique_tactic_ids()) {
                if (this.selectedTechniques.has(id)) return true;
            }
            return false;
        } else {
            if (this.selectSubtechniquesWithParent && walkChildren) { //check parent / children / siblings
                if (technique.isSubtechnique) { //select from parent
                    return this.isTechniqueSelected(technique.parent, tactic, true);
                } else {
                    for (let subtechnique of technique.subtechniques) {
                        if (this.isTechniqueSelected(subtechnique, tactic, false)) return true;
                    }
                }
            }
            return this.selectedTechniques.has(technique.get_technique_tactic_id(tactic));
        }
    }

    /**
     * return the number of selected techniques
     * @return {number} the number of selected techniques
     */
    public getSelectedTechniqueCount(): number {
        if (this.selectTechniquesAcrossTactics) {
            if (this.selectSubtechniquesWithParent) {
                // match across tactics
                // match subtechniques and parents

                // matches this part
                // vvvvv
                // T1001.001^TA1000
                let ids = new Set();
                this.selectedTechniques.forEach((unionID) => ids.add(unionID.split("^")[0].split(".")[0]));
                return ids.size;
            } else {
                // match across tactics
                // differentiate subtechniques and parents

                // matches this part
                // vvvvv vvv
                // T1001.001^TA1000
                let ids = new Set();
                this.selectedTechniques.forEach((unionID) => ids.add(unionID.split("^")[0]));
                return ids.size;
            }
        } else {
            if (this.selectSubtechniquesWithParent) {
                // differentiate tactics
                // match subtechniques and parents

                // matches this part
                // vvvvv     vvvvvv
                // T1001.001^TA1000
                let ids = new Set();
                this.selectedTechniques.forEach((unionID) => {
                    let split = unionID.split("^");
                    let tacticID = split[1];
                    let techniqueID = split[0].split(".")[0];
                    ids.add(techniqueID + "^" + tacticID);
                })
                return ids.size;
            } else {
                // differentiate tactics
                // differentiate subtechniques and parents

                // matches this part
                // vvvvv vvv vvvvvv
                // T1001.001^TA1000
                return this.selectedTechniques.size;
            }
        }
    }

    /**
     * Returns true if the given tactic is selected
     * @param  {Tactic}  tactic to check
     * @return {boolean} true if selected
     */
    public isTacticSelected(tactic: Tactic) {
        let self = this;
        var result = tactic.techniques.every(function(technique) {
            return self.isTechniqueSelected(technique, tactic)
        });
        return result;
    }


    /**
     * Return true if currently editing any techniques, false otherwise
     * @return {boolean} true if currently editing any techniques, false otherwise
     */
    public isCurrentlyEditing(): boolean {
        return this.getSelectedTechniqueCount() > 0;
    }

    /**
     * edit the selected techniques
     * @param {string} field the field to edit
     * @param {any}    value the value to place in the field
     */
    public editSelectedTechniques(field: string, value: any): void {
        this.selectedTechniques.forEach((id) => {
            this.getTechniqueVM_id(id)[field] = value;
        });
    }

    /**
     * Edit the selected techniques list attribute
     * @param {string}  field the field to edit
     * @param {(Link|Metadata)[]} values the list of values to place in the field
     */
    public editSelectedTechniqueValues(field: string, values: (Link | Metadata)[]): void {
        let fieldToType: any = {"links": Link, "metadata": Metadata};
        this.selectedTechniques.forEach(id => {
            const value_clone = values.map(value => { // deep copy
                let clone = new fieldToType[field]();
                clone.deSerialize(value.serialize());
                return clone;
            });
            this.getTechniqueVM_id(id)[field] = value_clone;
        });
    }

    /**
     * Reset the selected techniques' annotations to their default values
     */
    public resetSelectedTechniques(): void {
        this.selectedTechniques.forEach((id) => {
            this.getTechniqueVM_id(id).resetAnnotations();
        })
    }

    /**
     * Get get a common value from the selected techniques
     * @param  field the field to get the common value from
     * @return       the value of the field if all selected techniques have the same value, otherwise ""
     */
    public getEditingCommonValue(field: string): any {
        if (!this.isCurrentlyEditing()) return "";
        let ids = Array.from(this.selectedTechniques);
        let commonValue = this.getTechniqueVM_id(ids[0])[field];
        for (let i = 1; i < ids.length; i++) {
            if (this.getTechniqueVM_id(ids[i])[field] != commonValue) return ""
        }

        return commonValue;
    }

    activeTvm: TechniqueVM; // first selected techniqueVM
    linkMismatches: string[] = []; // subsequent selected technique_tactic_ids that do not have matching links
    public get linksMatch(): boolean { return !this.linkMismatches.length; }
    metadataMismatches: string[] = []; // subsequent selected technique_tactic_ids that do not have matching metadata
    public get metadataMatch(): boolean { return !this.metadataMismatches.length; }

    /**
     * If a technique has been selected, checks whether the link & metadata values of the selected technique match 
     * the link & metadata values of the first selected technique. If a technique has been deselected, removes it from
     * the lists of mismatching techniques (if applicable) or re-evalutes the lists of mismatching
     * techniques in the case where the deselected technique was the first selected technique
     * @param selected true if the technique was selected, false if it was deselected
     * @param id the technique_tactic_union_id of the technique
     */
    public checkValues(selected: boolean, id: string): void {
        if (selected) { // selected technique(s)
            let tvm = this.getTechniqueVM_id(id);
            if (this.activeTvm.linkStr !== tvm.linkStr) this.linkMismatches.push(id);
            if (this.activeTvm.metadataStr !== tvm.metadataStr) this.metadataMismatches.push(id);
        } else { // deselected technique(s)
            if (this.linkMismatches.includes(id)) this.linkMismatches.splice(this.linkMismatches.indexOf(id), 1);
            if (this.metadataMismatches.includes(id)) this.metadataMismatches.splice(this.metadataMismatches.indexOf(id), 1);

            if (this.activeTvm && this.activeTvm.technique_tactic_union_id == id) { // edge case where deselection was the first selected technique
                let first_id = this.selectedTechniques.values().next().value;
                this.activeTvm = first_id ? this.getTechniqueVM_id(first_id): undefined;

                // re-evaluate mismatched values
                this.linkMismatches = [];
                this.metadataMismatches = [];
                for (let technique_tactic_id of Array.from(this.selectedTechniques.values())) {
                    let tvm = this.getTechniqueVM_id(technique_tactic_id);
                    if (this.activeTvm.linkStr !== tvm.linkStr) this.linkMismatches.push(technique_tactic_id);
                    if (this.activeTvm.metadataStr !== tvm.metadataStr) this.metadataMismatches.push(technique_tactic_id);
                }
            }
        }
    }

    //  oooooooo8                          o8          o88 ooooooooooo o88   o888   o8
    // 888           ooooooo  oo oooooo  o888oo       o88   888    88  oooo   888 o888oo ooooooooo8 oo oooooo
    //  888oooooo  888     888 888    888 888       o88     888ooo8     888   888  888  888oooooo8   888    888
    //         888 888     888 888        888     o88       888         888   888  888  888          888
    // o88oooo888    88ooo88  o888o        888o o88        o888o       o888o o888o  888o  88oooo888 o888o
    //                                         o88
    //    ooooo ooooo            o888
    //     888   888  ooooooooo8  888 ooooooooo    ooooooooo8 oo oooooo    oooooooo8
    //     888ooo888 888oooooo8   888  888    888 888oooooo8   888    888 888ooooooo
    //     888   888 888          888  888    888 888          888                888
    //    o888o o888o  88oooo888 o888o 888ooo88     88oooo888 o888o       88oooooo88
    //                                o888

    /**
     * filter tactics according to viewmodel state
     * @param {Tactic[]} tactics to filter
     * @param {Matrix} matrix that the tactics fall under
     * @returns {Tactic[]} filtered tactics
     */
    public filterTactics(tactics: Tactic[], matrix: Matrix): Tactic[] {
        if (!this.loaded) return; // still initializing technique VMs
        return tactics.filter((tactic: Tactic) => this.filterTechniques(tactic.techniques, tactic, matrix).length > 0);
    }

    /**
     * filter techniques according to viewModel state
     * @param {Technique[]} techniques list of techniques to filter
     * @param {Tactic} tactic tactic the techniques fall under
     * @param {Matrix} matrix that the techniques fall under
     * @returns {Technique[]} filtered techniques
     */
    public filterTechniques(techniques: Technique[], tactic: Tactic, matrix: Matrix): Technique[] {
        return techniques.filter((technique: Technique) => {
            let techniqueVM = this.getTechniqueVM(technique, tactic);
            // filter by enabled
            if (this.hideDisabled && !this.isSubtechniqueEnabled(technique, techniqueVM, tactic)) return false;
            if (matrix.name == "PRE-ATT&CK") return true; // don't filter by platform if it's pre-attack
            // filter by platform
            let platforms = new Set(technique.platforms)
            for (let platform of this.filters.platforms.selection) {
                if (platforms.has(platform)) return true; //platform match
            }
            return false; //no platform match
        })
    }

    public isSubtechniqueEnabled(technique, techniqueVM, tactic): boolean {
        if (techniqueVM.enabled) return true;
        else if (technique.subtechniques.length > 0) {
            return technique.subtechniques.some(subtechnique => {
                let sub_platforms = new Set(subtechnique.platforms);
                let filter = new Set(this.filters.platforms.selection);
                let platforms = new Set(Array.from(filter.values()).filter(p => sub_platforms.has(p)));
                return this.getTechniqueVM(subtechnique, tactic).enabled && platforms.size > 0;
            });
        }
        else return false;
    }

    /**
     * sort techniques according to viewModel state
     * @param {Technique[]} techniques techniques to sort
     * @param {Tactic} tactic tactic the techniques fall under
     * @returns {Technique[]} sorted techniques
     */
    public sortTechniques(techniques: Technique[], tactic: Tactic): Technique[] {
        return techniques.sort((technique1: Technique, technique2: Technique) => {
            const techniqueVM1 = this.getTechniqueVM(technique1, tactic);
            const techniqueVM2 = this.getTechniqueVM(technique2, tactic);

            this.sortSubTechniques(technique1, tactic);
            this.sortSubTechniques(technique2, tactic);

            // prefer techniques scored 0 over unscored
            let score1 = techniqueVM1.score.length > 0 ? Number(techniqueVM1.score) : Number.NEGATIVE_INFINITY;
            let score2 = techniqueVM2.score.length > 0 ? Number(techniqueVM2.score) : Number.NEGATIVE_INFINITY;

            if (this.layout.showAggregateScores) {
                // if enabled, factor aggregate scores of parent techniques into sorting
                if (technique1.subtechniques.length > 0) score1 = this.calculateAggregateScore(technique1, tactic);
                if (technique2.subtechniques.length > 0) score2 = this.calculateAggregateScore(technique2, tactic);
            }
            return this.sortingAlgorithm(technique1, technique2, score1, score2);
        });
    }

    /**
     * sort subtechniques according to viewModel state
     * @param {Technique} technique technique to sort
     * @param {Tactic} tactic tactic the technique falls under
     */
    public sortSubTechniques(technique: Technique, tactic: Tactic) {
        technique.subtechniques.sort((technique1: Technique, technique2: Technique) => {
            const techniqueVM1 = this.getTechniqueVM(technique1, tactic);
            const techniqueVM2 = this.getTechniqueVM(technique2, tactic);
            const score1 = techniqueVM1.score.length > 0 ? Number(techniqueVM1.score) : 0;
            const score2 = techniqueVM2.score.length > 0 ? Number(techniqueVM2.score) : 0;
            return this.sortingAlgorithm(technique1, technique2, score1, score2);
        });
    }

    /**
     * execute the sorting algorithm for techniques according to the viewModel state
     * @param {Technique} technique1 the first technique in the comparison
     * @param {Technique} technique2 the second technique in the comparison
     * @param {number} score1 the first score in the comparison
     * @param {number} score2 the second score in the comparison
     * @returns technique or score comparison
     */
    private sortingAlgorithm(technique1: Technique, technique2: Technique, score1: number, score2: number) {
        switch (this.sorting) {
            default:
            case 0: // A-Z
                return technique1.name.localeCompare(technique2.name);
            case 1: // Z-A
                return technique2.name.localeCompare(technique1.name);
            case 2: // 1-2
                if (score1 === score2) {
                    return technique1.name.localeCompare(technique2.name);
                } else {
                    return score1 - score2;
                }
            case 3: // 2-1
                if (score1 === score2) {
                    return technique1.name.localeCompare(technique2.name);
                } else {
                    return score2 - score1;
                }
        }
    }

    public calculateAggregateScore(technique: Technique, tactic: Tactic): any {
        const tvm = this.getTechniqueVM(technique, tactic);
        let score = tvm.score.length > 0 ? Number(tvm.score) : 0;
        let validTechniquesCount = tvm.score.length > 0 ? 1 : 0;
        let scores = [score];

        technique.subtechniques.forEach((subtechnique) => {
            const svm = this.getTechniqueVM(subtechnique, tactic);
            const scoreNum = svm.score.length > 0 ? Number(svm.score) : 0;
            if (svm.score.length > 0) {
                validTechniquesCount += 1;
                scores.push(scoreNum);
            }
        });

        if (validTechniquesCount === 0) return tvm.score.length > 0 ? score : Number.NEGATIVE_INFINITY;

        let aggScore: any = 0;

        switch (this.layout.aggregateFunction) {
            default:
            case "average":
                // Divide by count of all subtechniques + 1 (for parent technique) if counting unscored is enabled
                // Otherwise, divide by count of all scored only
                score = scores.reduce((a, b) => a + b);
                aggScore = score / (this.layout.countUnscored ? (technique.subtechniques.length + 1) : validTechniquesCount);
                break;
            case "min":
                if (scores.length > 0) aggScore = Math.min(...scores);
                break;
            case "max":
                if (scores.length > 0) aggScore = Math.max(...scores);
                break;
            case "sum":
                aggScore = scores.reduce((a, b) => a + b);
                break;
        }

        aggScore = aggScore.toFixed(2);
        tvm.aggregateScoreColor = this.gradient.getColor(aggScore.toString());
        tvm.aggregateScore = Number.isFinite(+aggScore) ? (+aggScore).toString() : "";
        return +aggScore;
    }

    /**
     * apply sort and filter state to techniques
     * @param {Technique[]} techniques techniques to sort and filter
     * @param {Tactic} tactic that the techniques fall under
     * @param {Matrix} matrix that the techniques fall under
     * @returns {Technique[]} sorted and filtered techniques
     */
    public applyControls(techniques: Technique[], tactic: Tactic, matrix: Matrix): Technique[] {
        //apply sort and filter
        return this.sortTechniques(this.filterTechniques(techniques, tactic, matrix), tactic);
    }





    //  ___ ___ ___ ___   _   _    ___ ____  _ _____ ___ ___  _  _
    // / __| __| _ \_ _| /_\ | |  |_ _|_  / /_\_   _|_ _/ _ \| \| |
    // \__ \ _||   /| | / _ \| |__ | | / / / _ \| |  | | (_) | .` |
    // |___/___|_|_\___/_/ \_\____|___/___/_/ \_\_| |___\___/|_|\_|

    /**
     * stringify this vm
     * @return string representation
     */
    serialize(): string {
        let modifiedTechniqueVMs = []
        let self = this;
        this.techniqueVMs.forEach(function(value,key) {
            if (value.modified()) modifiedTechniqueVMs.push(JSON.parse(value.serialize())) //only save techniqueVMs which have been modified
        })
        let rep: {[k: string]: any } = {};
        rep.name = this.name;

        rep.versions = {
            "attack": this.dataService.getDomain(this.domainVersionID).getVersion(),
            "navigator": globals.nav_version,
            "layer": globals.layer_version
        }

        let domain = this.dataService.getDomain(this.domainVersionID);
        rep.domain = domain.domain_identifier;
        if (domain.isCustom) {
            // custom data url
            rep.customDataURL = domain.urls[0];
        }
        rep.description = this.description;
        rep.filters = JSON.parse(this.filters.serialize());
        rep.sorting = this.sorting;
        rep.layout = this.layout.serialize();
        rep.hideDisabled = this.hideDisabled;
        rep.techniques = modifiedTechniqueVMs;
        rep.gradient = JSON.parse(this.gradient.serialize());
        rep.legendItems = JSON.parse(JSON.stringify(this.legendItems));
        rep.metadata = this.metadata.filter(m => m.valid()).map(m => m.serialize());
        rep.links = this.links.filter(l => l.valid()).map(l => l.serialize());

        rep.showTacticRowBackground = this.showTacticRowBackground;
        rep.tacticRowBackground = this.tacticRowBackground;
        rep.selectTechniquesAcrossTactics = this.selectTechniquesAcrossTactics;
        rep.selectSubtechniquesWithParent = this.selectSubtechniquesWithParent;

        return JSON.stringify(rep, null, "\t");
    }

    /**
     * restore the domain and version from a string
     * @param rep string to restore from
     */
    deSerializeDomainVersionID(rep: any): void {
        let obj = (typeof(rep) == "string")? JSON.parse(rep) : rep
        this.name = obj.name
        this.version = this.dataService.getCurrentVersion().number; // layer with no specified version defaults to current version
        if ("versions" in obj) {
            if ("attack" in obj.versions) {
                if (typeof(obj.versions.attack) === "string") {
                    if (obj.versions.attack.length > 0) this.version = obj.versions.attack.match(/[0-9]+/g)[0];
                }
                else console.error("TypeError: attack version field is not a string");
            }
            if(obj.versions["layer"] !== globals.layer_version){
                alert("WARNING: Uploaded layer version (" + String(obj.versions["layer"]) + ") does not match Navigator's layer version ("
                + String(globals.layer_version) + "). The layer configuration may not be fully restored.");
            }
        }
        if ("version" in obj) { // backwards compatibility with Layer Format 3
            if (obj.version !== globals.layer_version){
                alert("WARNING: Uploaded layer version (" + String(obj.version) + ") does not match Navigator's layer version ("
                + String(globals.layer_version) + "). The layer configuration may not be fully restored.");
            }
        }
        // patch for old domain name convention
        if(obj.domain in this.dataService.domain_backwards_compatibility) {
            this.domain = this.dataService.domain_backwards_compatibility[obj.domain];
        } else { this.domain = obj.domain; }
        this.domainVersionID = this.dataService.getDomainVersionID(this.domain, this.version);
    }

    /**
     * restore this vm from a string
     * @param  rep string to restore from
     */
    deSerialize(rep: any): void {
        let obj = (typeof(rep) == "string")? JSON.parse(rep) : rep

        if ("description" in obj) {
            if (typeof(obj.description) === "string") this.description = obj.description;
            else console.error("TypeError: description field is not a string")
        }
        if ("filters" in obj) { this.filters.deSerialize(obj.filters); }
        if ("sorting" in obj) {
            if (typeof(obj.sorting) === "number") this.sorting = obj.sorting;
            else console.error("TypeError: sorting field is not a number")
        }
        if ("hideDisabled" in obj) {
            if (typeof(obj.hideDisabled) === "boolean") this.hideDisabled = obj.hideDisabled;
            else console.error("TypeError: hideDisabled field is not a boolean")
        }

        if ("gradient" in obj) {
            this.gradient = new Gradient();
            this.gradient.deSerialize(JSON.stringify(obj.gradient))
        }

        if ("legendItems" in obj) {
            for (let i = 0; i < obj.legendItems.length; i++) {
                let legendItem = {
                    color: "#defa217",
                    label: "default label"
                };
                if (!("label" in obj.legendItems[i])) {
                    console.error("Error: LegendItem required field 'label' not present")
                    continue;
                }
                if (!("color" in obj.legendItems[i])) {
                    console.error("Error: LegendItem required field 'label' not present")
                    continue;
                }

                if (typeof(obj.legendItems[i].label) === "string") {
                    legendItem.label = obj.legendItems[i].label;
                } else {
                    console.error("TypeError: legendItem label field is not a string")
                    continue
                }

                if (typeof(obj.legendItems[i].color) === "string" && tinycolor(obj.legendItems[i].color).isValid()) {
                    legendItem.color = obj.legendItems[i].color;
                } else {
                    console.error("TypeError: legendItem color field is not a color-string:", obj.legendItems[i].color, "(", typeof(obj.legendItems[i].color),")")
                    continue
                }
                this.legendItems.push(legendItem);
            }
        }

        if ("showTacticRowBackground" in obj) {
            if (typeof(obj.showTacticRowBackground) === "boolean") this.showTacticRowBackground = obj.showTacticRowBackground
            else console.error("TypeError: showTacticRowBackground field is not a boolean")
        }
        if ("tacticRowBackground" in obj) {
            if (typeof(obj.tacticRowBackground) === "string" && tinycolor(obj.tacticRowBackground).isValid()) this.tacticRowBackground = obj.tacticRowBackground;
            else console.error("TypeError: tacticRowBackground field is not a color-string:", obj.tacticRowBackground, "(", typeof(obj.tacticRowBackground),")")
        }
        if ("selectTechniquesAcrossTactics" in obj) {
            if (typeof(obj.selectTechniquesAcrossTactics) === "boolean") this.selectTechniquesAcrossTactics = obj.selectTechniquesAcrossTactics
            else console.error("TypeError: selectTechniquesAcrossTactics field is not a boolean")
        }
        if ("selectSubtechniquesWithParent" in obj) {
            if (typeof(obj.selectSubtechniquesWithParent) === "boolean") this.selectSubtechniquesWithParent = obj.selectSubtechniquesWithParent
            else console.error("TypeError: selectSubtechniquesWithParent field is not a boolean")
        }
        if ("techniques" in obj) {
            if(obj.techniques.length > 0) {
                for (let i = 0; i < obj.techniques.length; i++) {
                    var obj_technique = obj.techniques[i];
                    if ("tactic" in obj_technique) {
                        let tvm = new TechniqueVM("");
                        tvm.deSerialize(JSON.stringify(obj_technique),
                                        obj_technique.techniqueID,
                                        obj_technique.tactic);
                        this.setTechniqueVM(tvm);
                    } else {
                        // occurs in multiple tactics
                        // match to Technique by attackID
                        for (let technique of this.dataService.getDomain(this.domainVersionID).techniques) {
                            if (technique.attackID == obj_technique.techniqueID) {
                                // match technique
                                // don't load deprecated/revoked, causes crash since tactics don't get loaded on revoked techniques
                                if (technique.deprecated || technique.revoked) break;

                                for (let tactic of technique.tactics) {
                                    let tvm = new TechniqueVM("");
                                    tvm.deSerialize(JSON.stringify(obj_technique),
                                                    obj_technique.techniqueID,
                                                    tactic);
                                    this.setTechniqueVM(tvm);
                                }
                                break;
                            }
                            //check against subtechniques
                            for (let subtechnique of technique.subtechniques) {
                                if (subtechnique.attackID == obj_technique.techniqueID) {
                                    // don't load deprecated/revoked, causes crash since tactics don't get loaded on revoked techniques
                                    if (subtechnique.deprecated || subtechnique.revoked) break;

                                    for (let tactic of subtechnique.tactics) {
                                        let tvm = new TechniqueVM("");
                                        tvm.deSerialize(JSON.stringify(obj_technique),
                                                        obj_technique.techniqueID,
                                                        tactic);
                                        this.setTechniqueVM(tvm);
                                    }
                                    break;
                                }
                            }
                        }

                    }
                }
            }
        }
        if ("metadata" in obj) {
            for (let metadataObj of obj.metadata) {
                let m = new Metadata();
                m.deSerialize(metadataObj);
                if (m.valid()) this.metadata.push(m)
            }
        }
        if ("links" in obj) {
            for (let link of obj.links) {
                let l = new Link();
                l.deSerialize(link);
                if (l.valid()) this.links.push(l);
            }
        }
        // add custom data URL
        if ("customDataURL" in obj) {
            this.bundleURL = obj.customDataURL;
        }
        if ("layout" in obj) {
            this.layout.deserialize(obj.layout);
        }
        else if ("viewMode" in obj) {
            /*
             * viewMode backwards compatibility:
             * 0: full table (side layout, show name)
             * 1: compact table (side layout, show ID)
             * 2: mini table (mini layout, show neither name nor ID)
             */
            if (typeof(obj.viewMode) === "number") {
                switch(obj.viewMode) {
                    default:
                    case 0:
                        break; //default matrix layout already initialized
                    case 1:
                        this.layout.layout = "side";
                        this.layout.showName = false;
                        this.layout.showID = true;
                        break;
                    case 2:
                        this.layout.layout = "mini";
                        this.layout.showName = false;
                        this.layout.showID = false;
                }
            }
            else console.error("TypeError: viewMode field is not a number")
        }

        this.updateGradient();
    }

    /**
     * Add a color to the end of the gradient
     */
    addGradientColor(): void {
        this.gradient.addColor();
        this.updateGradient();
    }

    /**
     * Remove color at the given index
     * @param index index to remove color at
     */
    removeGradientColor(index: number): void {
        this.gradient.removeColor(index)
        this.updateGradient();
    }

    /**
     * Update this vm's gradient
     */
    updateGradient(): void {
        console.log("updating gradient")
        this.gradient.updateGradient();
        let self = this;
        this.techniqueVMs.forEach(function(tvm, key) {
            tvm.scoreColor = self.gradient.getColor(tvm.score);
        });
        this.updateLegendColorPresets();
    }

    /**
     * Update the score color of a single technique VM to match the current
     * score and gradient
     * @param tvm technique VM to update
     */
    updateScoreColor(tvm: TechniqueVM): void {
        tvm.scoreColor = this.gradient.getColor(tvm.score);
    }

    legendItems = [

    ];

    addLegendItem(): void {
        var newObj = {
            label: "NewItem",
            color: '#00ffff'
        }
        this.legendItems.push(newObj);
    }

    deleteLegendItem(index: number): void {
        this.legendItems.splice(index,1);
    }

    clearLegend(): void {
        this.legendItems = [];
    }

    updateLegendColorPresets(): void {
        this.legendColorPresets = [];
        for(var i = 0; i < this.backgroundPresets.length; i++){
            this.legendColorPresets.push(this.backgroundPresets[i]);
        }
        for(var i = 0; i < this.gradient.colors.length; i++){
            this.legendColorPresets.push(this.gradient.colors[i].color);
        }
    }

    /**
     * return an acronym version of the given string
     * @param  words the string of words to get the acrnoym of
     * @return       the acronym string
     */
    acronym(words: string): string {
        let skipWords = ["on","and", "the", "with", "a", "an", "of", "in", "for", "from"]

        let result = "";
        let wordSplit = words.split(" ");
        if (wordSplit.length > 1) {
            let wordIndex = 0;
            // console.log(wordSplit);
            while (result.length < 4 && wordIndex < wordSplit.length) {
                if (skipWords.includes(wordSplit[wordIndex].toLowerCase())) {
                    wordIndex++;
                    continue;
                }

                //find first legal char of word
                for (let charIndex = 0; charIndex < wordSplit[wordIndex].length; charIndex++) {
                    let code = wordSplit[wordIndex].charCodeAt(charIndex);
                    if (code < 48 || (code > 57 && code < 65) || (code > 90 && code < 97) || code > 122) { //illegal character
                        continue;
                    } else {
                        result += wordSplit[wordIndex].charAt(charIndex).toUpperCase()
                        break;
                    }
                }

                wordIndex++;
            }

            return result;
        } else {
            return wordSplit[0].charAt(0).toUpperCase();
        }
    }
}

// the viewmodel for a specific technique
export class TechniqueVM {
    techniqueID: string;
    technique_tactic_union_id: string;
    tactic: string;

    score: string = "";
    scoreColor: any; //color for score gradient

    color: string = ""; //manually assigned color-class name
    enabled: boolean = true;
    comment: string = ""
    metadata: Metadata[] = [];
    public get metadataStr(): string { return JSON.stringify(this.metadata); }
    links: Link[] = [];
    public get linkStr(): string { return JSON.stringify(this.links); }

    showSubtechniques = false;
    aggregateScore: any; // number rather than string as this is not based on an input from user
    aggregateScoreColor: any;

    //print this object to the console
    print(): void {
        console.log(this.serialize())
        console.log(this)
    }

    /**
     * Has this TechniqueVM been modified from its initialized state?
     * @return true if it has been modified, false otherwise
     */
    modified(): boolean {
        return (this.annotated() || this.showSubtechniques);
    }

    /**
     * Check if this TechniqueVM has been annotated
     * @return true if it has annotations, false otherwise
     */
    annotated(): boolean {
        return (this.score != "" || this.color != "" || !this.enabled || this.comment != "" || this.links.length !== 0 || this.metadata.length !== 0);
    }

    /**
     * Reset this TechniqueVM's annotations to their default values
     */
    resetAnnotations(): void {
        this.score = "";
        this.comment = "";
        this.color = "";
        this.enabled = true;
        this.aggregateScore = "";
        this.aggregateScoreColor = "";
        this.links = [];
        this.metadata = [];
    }

    /**
     * Convert to string representation
     * @return string representation
     */
    serialize(): string {
        let rep: {[k: string]: any } = {};
        rep.techniqueID = this.techniqueID;
        rep.tactic = this.tactic;
        if (this.score !== "" && !(isNaN(Number(this.score)))) rep.score = Number(this.score);
        rep.color = this.color;
        rep.comment = this.comment;
        rep.enabled = this.enabled;
        rep.metadata = this.metadata.filter(m => m.valid()).map(m => m.serialize());
        rep.links = this.links.filter(l => l.valid()).map(l => l.serialize());
        rep.showSubtechniques = this.showSubtechniques;
        return JSON.stringify(rep, null, "\t")
    }

    /**
     * Restore this technique from serialized technique
     * @param rep serialized technique string
     */
    deSerialize(rep: string, techniqueID: string, tactic: string): void {
        let obj = JSON.parse(rep);
        if (techniqueID !== undefined) this.techniqueID = techniqueID;
        else console.error("ERROR: TechniqueID field not present in technique")

        if (tactic !== undefined && tactic !== "") this.tactic = tactic;
        else {
            console.error("WARNING: tactic field not present in technique");
            alert(`WARNING: The tactic field on the technique ID ${techniqueID} is not defined. Annotations for this technique may not be restored.`);
        }
        if ("comment" in obj) {
            if (typeof(obj.comment) === "string") this.comment = obj.comment;
            else console.error("TypeError: technique comment field is not a number:", obj.comment, "(",typeof(obj.comment),")")
        }
        if ("color" in obj && obj.color !== "") {
            if (typeof(obj.color) === "string" && tinycolor(obj.color).isValid()) this.color = obj.color;
            else console.error("TypeError: technique color field is not a color-string:", obj.color, "(", typeof(obj.color),")")
        }
        if ("score" in obj) {
            if (typeof(obj.score) === "number") this.score = String(obj.score);
            else console.error("TypeError: technique score field is not a number:", obj.score, "(", typeof(obj.score), ")")
        }
        if ("enabled" in obj) {
            if (typeof(obj.enabled) === "boolean") this.enabled = obj.enabled;
            else console.error("TypeError: technique enabled field is not a boolean:", obj.enabled, "(", typeof(obj.enabled), ")");
        }
        if ("showSubtechniques" in obj) {
            if (typeof(obj.showSubtechniques) === "boolean") this.showSubtechniques = obj.showSubtechniques;
            else console.error("TypeError: technique showSubtechnique field is not a boolean:", obj.showSubtechniques, "(", typeof(obj.showSubtechniques), ")");
        }
        if (this.tactic !== undefined && this.techniqueID !== undefined) {
            this.technique_tactic_union_id = this.techniqueID + "^" + this.tactic;
        } else {
            console.log("ERROR: Tactic and TechniqueID field needed.")
        }

        if ("metadata" in obj) {
            for (let metadataObj of obj.metadata) {
                let m = new Metadata();
                m.deSerialize(metadataObj);
                if (m.valid()) this.metadata.push(m)
            }
        }
        if ("links" in obj) {
            for (let linkObj of obj.links) {
                let link = new Link();
                link.deSerialize(linkObj);
                if (link.valid()) this.links.push(link);
            }
        }
    }

    constructor(technique_tactic_union_id: string) {
        this.technique_tactic_union_id = technique_tactic_union_id;
        var idSplit = technique_tactic_union_id.split("^");
        this.techniqueID = idSplit[0];
        this.tactic = idSplit[1];
    }
}

// the data for a specific filter
export class Filter {
    private readonly domain: string;
    platforms: {
        options: string[],
        selection: string[]
    }
    constructor() {
        this.platforms = {
            selection: [],
            options: []
        }
    }

    /**
     * Initialize the platform options according to the data in the domain
     * @param {Domain} domain the domain to parse for platform options
     */
    public initPlatformOptions(domain: Domain): void {
        this.platforms.options = JSON.parse(JSON.stringify(domain.platforms));
        if (!this.platforms.selection.length) { // prevent overwriting current selection
            this.platforms.selection = JSON.parse(JSON.stringify(domain.platforms));
        }
    }

    /**
     * toggle the given value in the given filter
     * @param {*} filterName the name of the filter
     * @param {*} value the value to toggle
     */
    toggleInFilter(filterName: string, value: string): void {
        if (!this[filterName].options.includes(value)) { console.log("not a valid option to toggle", value, this[filterName]); return }
        if (this[filterName].selection.includes(value)) {
            let index = this[filterName].selection.indexOf(value)
            this[filterName].selection.splice(index, 1);
        } else {
            this[filterName].selection.push(value);
        }
    }

    /**
     * determine if the given value is active in the filter
     * @param {*} filterName the name of the filter
     * @param {*} value the value to determine
     * @returns {boolean} true if value is currently enabled in the filter
     */
    inFilter(filterName, value): boolean {
        return this[filterName].selection.includes(value)
    }

    /**
     * Return the string representation of this filter
     * @return stringified filter
     */
    serialize(): string {
        return JSON.stringify({"platforms": this.platforms.selection})
    }

    /**
     * Replace the properties of this object with those of the given serialized filter
     * @param rep filter object
     */
    deSerialize(rep: any): void {
        // console.log(rep)
        let isStringArray = function(check): boolean {
            for (let i = 0; i < check.length; i++) {
                if (typeof(check[i]) !== "string") {
                    console.error("TypeError:", check[i], "(",typeof(check[i]),")", "is not a string")
                    return false;
                }

            }
            return true;
        }
        // let obj = JSON.parse(rep);
        if (rep.platforms) {
            if (isStringArray(rep.platforms)) {
                let backwards_compatibility_mappings = { //backwards compatibility with older layers
                    "android": "Android",
                    "ios": "iOS",

                    "windows": "Windows",
                    "linux": "Linux",
                    "mac": "macOS",

                    "AWS": "IaaS",
                    "GCP": "IaaS",
                    "Azure": "IaaS"
                }
                const selection = new Set<string>();
                rep.platforms.forEach(function (platform) {
                    if (platform in backwards_compatibility_mappings) selection.add(backwards_compatibility_mappings[platform]);
                    else selection.add(platform);
                });
                this.platforms.selection = Array.from(selection);
            } else console.error("TypeError: filter platforms field is not a string[]");
        }
    }
}

// { name, value } with serialization
export class Metadata {
    public name: string;
    public value: string;
    public divider: boolean;

    constructor() { }

    serialize(): object {
        return this.name && this.value ? {name: this.name, value: this.value} : {divider: this.divider};
    }

    deSerialize(rep: any): void {
        let obj = (typeof(rep) == "string")? JSON.parse(rep) : rep;
        if ("name" in obj) { // name & value object
            if (typeof(obj.name) === "string") this.name = obj.name;
            else console.error("TypeError: Metadata field 'name' is not a string");

            if ("value" in obj) {
                if (typeof(obj.value) === "string") this.value = obj.value;
                else console.error("TypeError: Metadata field 'value' is not a string")
            }
            else console.error("Error: Metadata required field 'value' not present");
        }
        else if ("divider" in obj) { // divider object
            if (typeof(obj.divider) === "boolean") this.divider = obj.divider;
            else  console.error("TypeError: Metadata field 'divider' is not a boolean");
        }
        else console.error("Error: Metadata required field 'name' or 'divider' not present");
    }

    valid(): boolean {
        return (this.name && this.name.length > 0 && this.value && this.value.length > 0) || (this.divider !== undefined)
    }
}

// { label, url } with serialization
export class Link {
    public label: string;
    public url: string;
    public divider: boolean;

    constructor() { }

    serialize(): object { 
        return this.label && this.url ? {label: this.label, url: this.url} : {divider: this.divider};
    }

    deSerialize(rep: any): void {
        let obj = (typeof(rep) == "string")? JSON.parse(rep) : rep;
        if ("url" in obj) { // label & url object
            if (typeof(obj.url) === "string") this.url = obj.url;
            else console.error("TypeError: Link field 'url' is not a string");

            if ("label" in obj) {
                if (typeof(obj.label) === "string") this.label = obj.label;
                else console.error("TypeError: Link field 'label' is not a string");
            }
            else console.error("Error: Link required field 'label' not present");
        }
        else if ("divider" in obj) { // divider object
            if (typeof(obj.divider) === "boolean") this.divider = obj.divider;
            else  console.error("TypeError: Link field 'divider' is not a boolean");
        }
        else console.error("Error: Link required field 'url' or 'divider' not present");
    }

    valid(): boolean {
        return (this.label && this.label.length > 0 && this.url && this.url.length > 0) || (this.divider !== undefined)
    }
}

export class LayoutOptions {
    // current layout selection
    public readonly layoutOptions: string[] = ["side", "flat", "mini"];
    private _layout = this.layoutOptions[0]; //current selection
    public set layout(newLayout) {
        if (!this.layoutOptions.includes(newLayout)) {
            console.warn("invalid matrix layout", newLayout);
            return;
        }
        let oldLayout = this._layout;
        this._layout = newLayout;
        if (this._layout == "mini") { //mini-table cannot show ID or name
            this.showID = false;
            this.showName = false;
        }
        if (oldLayout == "mini" && newLayout != "mini") {
            this.showName = true; //restore default show value for name
        }
    }
    public get layout(): string { return this._layout; }

    public readonly aggregateFunctionOptions: string[] = ["average", "min", "max", "sum"];
    private _aggregateFunction = this.aggregateFunctionOptions[0];
    public set aggregateFunction(newAggregateFunction) {
        if (!this.aggregateFunctionOptions.includes(newAggregateFunction)) {
            console.warn("invalid aggregate fx option", newAggregateFunction);
            return;
        }
        this._aggregateFunction = newAggregateFunction;
    }

    public get aggregateFunction(): string { return this._aggregateFunction; }

    //show technique/tactic IDs in the view?
    public _showID: boolean = false;
    public set showID(newval: boolean) {
        this._showID = newval;
        if (newval == true && this._layout == "mini") this._layout = "side";
    }
    public get showID(): boolean { return this._showID; }

    //show technique/tactic names in the view?
    public _showName: boolean = true;
    public set showName(newval: boolean) {
        this._showName = newval;
        if (newval == true && this._layout == "mini") this._layout = "side";
    }
    public get showName(): boolean { return this._showName; }

    public _showAggregateScores: boolean = false;
    public set showAggregateScores(newval: boolean) { this._showAggregateScores = newval; }
    public get showAggregateScores(): boolean { return this._showAggregateScores; }

    public _countUnscored: boolean = false;
    public set countUnscored(newval: boolean) { this._countUnscored = newval; }
    public get countUnscored(): boolean { return (this.aggregateFunction === "average") ? this._countUnscored : false; }

    public serialize(): object {
        return {
            "layout": this.layout,
            "aggregateFunction": this.aggregateFunction,
            "showID": this.showID,
            "showName": this.showName,
            "showAggregateScores": this.showAggregateScores,
            "countUnscored": this.countUnscored
        };
    }

    public deserialize(rep: any) {
        if ("showID" in rep) {
            if (typeof (rep.showID) === "boolean") this.showID = rep.showID;
            else console.error("TypeError: layout field 'showID' is not a boolean:", rep.showID, "(", typeof (rep.showID), ")");
        }
      if ("showName" in rep) {
          if (typeof (rep.showName) === "boolean") this.showName = rep.showName;
          else console.error("TypeError: layout field 'showName' is not a boolean:", rep.showName, "(", typeof (rep.showName), ")");
      }
      //make sure this one goes last so that it can override name and ID if layout == 'mini'
      if ("layout" in rep) {
          if (typeof (rep.layout) === "string") this.layout = rep.layout;
          else console.error("TypeError: layout field 'layout' is not a string:", rep.layout, "(", typeof (rep.layout), ")");
      }
      if ("aggregateFunction" in rep) {
          if (typeof (rep.aggregateFunction) === "string") this.aggregateFunction = rep.aggregateFunction;
          else console.error("TypeError: layout field 'aggregateFunction' is not a boolean:", rep.aggregateFunction, "(", typeof (rep.aggregateFunction), ")");
      }
      if ("showAggregateScores" in rep) {
          if (typeof (rep.showAggregateScores) === "boolean") this.showAggregateScores = rep.showAggregateScores;
          else console.error("TypeError: layout field 'showAggregateScores' is not a boolean:", rep.showAggregateScores, "(", typeof (rep.showAggregateScores), ")");
      }
      if ("countUnscored" in rep) {
          if (typeof (rep.countUnscored) === "boolean") this.countUnscored = rep.countUnscored;
          else console.error("TypeError: layout field 'countUnscored' is not a boolean:", rep.countUnscored, "(", typeof (rep.countUnscored), ")");
      }
  }
}


