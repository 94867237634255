<div [style.margin-top]="adjustedHeaderHeight+'px'" class="header-wrapper">
  <div class="header-inner">
    <!-- Header Logo -->
    <div class="header-logo">
      <a aria-label="corelight-logo-white" class="logo"
         href="https://corelight-com.sandbox.hs-sites.com/?hsLang=en&amp;__hstc=48219256.f81ef1ed8ca28c0aed044b719e3752da.1683631458195.1683631458195.1683631458195.1&amp;__hssc=48219256.1.1683631458195&amp;__hsfp=351376363"
         target="_blank">
        <img class="reg-logo" src="assets/logo/corelight-logo-white.svg"
             alt="corelight-logo-white">
      </a>
    </div>
    <div class="header-title"><h1>MITRE ATT&CK<sup>®</sup> COVERAGE</h1></div>
    <div class="header-legend">Technique covered by Corelight</div>
  </div>
<!-- Banner -->
<div *ngIf="hasFeature('header') && bannerContent" class="header">
    <div class="banner">
        <span class="bannerContent" role="alert" [innerHTML]="bannerContent"></span>
        <div [style.margin-bottom]="hasFeature('tabs') ? '0px' : '15px'" class="bannerLinks">
            <div>
                <a href="http://attack.mitre.org">MITRE ATT&CK&reg; Navigator</a>
            </div>
        </div>
    </div>
</div>

<!-- Header/Help -->
<div *ngIf="hasFeature('header') && !bannerContent" [style.margin-bottom]="hasFeature('tabs') ? '0px' : '15px'"
     class="header help-header">
    <div *ngIf="hasFeature('tabs')" class="help-button" [ngClass]="{'help-dropdown-open' : showHelpDropDown}"
         matTooltip="help" (click)="showHelpDropDown = !showHelpDropDown">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9aa0a6"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg>
    </div>
    <div class="dropdown-container" *ngIf="showHelpDropDown && adjustedHeaderHeight >= 0">
        <button (click)="openDialog('help')">help</button>
        <button (click)="openDialog('changelog')">changelog</button>
    </div>
</div>


<!-- Tabs display -->
<!--div class="tabs-container">
    <nav mat-tab-nav-bar class="tabs">
        <a mat-tab-link
           class="tab-title"
           [class.active]="activeTab === tab"
           *ngFor="let tab of layerTabs; let $i = index"
           (click)="handleTabClick(tab)"
           [active]="activeTab === tab">
            {{tab.isDataTable ? tab.dataContext.name : tab.title}}
        </a>
    </nav>
</div-->


<div *ngIf="layerTabs.length == 0" class="newTab">
    <!-- you shouldn't ever see this, but just in case -->
    <button (click)="newBlankTab()">Start</button>
</div>

</div>


<ng-template #safariWarning>
    <div class="safari-warning">
        <h3>WARNING</h3>
        <p>
            We’ve detected that you are using the Safari browser. As of Navigator version <b>4.3</b>, Safari versions 13
            and below are not supported due to an unfixable freeze that can occur when selecting a layer tab.
        </p>
        <p>
            We recommend you use Chrome or Firefox instead. You can continue to use the Navigator in Safari (versions 13
            and below), but you may lose work if the application freezes.
        </p>
        <button mat-button (click)="safariDialogRef.close()">Dismiss</button>
    </div>
</ng-template>

<!-- New tab template -->
<ng-template [ngIf]="activeTab && !activeTab.isDataTable">
    <div class="newTab">
        <div class="description">
            <div class="logo">
                <h1><a href="http://attack.mitre.org">MITRE ATT&CK&reg; Navigator</a></h1>
            </div>
            <div>
                <p>
                    The ATT&CK Navigator is a web-based tool for annotating and exploring ATT&CK matrices. It can be
                    used to visualize defensive coverage, red/blue team planning, the frequency of detected techniques,
                    and more.
                </p>
            </div>
            <!-- Help dialog -->
            <div class="help-links-container">
                <button mat-button (click)="openDialog('help')">help</button>
                <button mat-button (click)="openDialog('changelog')">changelog</button>


                <button mat-button (click)="select.open()" class="theme-select-container">
                    <mat-label>
                        theme ▾
                    </mat-label>
                    <mat-select #select (selectionChange)="handleUserThemeChange($event.value)">
                        <mat-option value="dark">dark</mat-option>
                        <mat-option value="light">light</mat-option>
                        <mat-option value="system">use system</mat-option>
                    </mat-select>
                </button>
            </div>
        </div>
        <mat-accordion class="headers-align">

            <!-- Create new layer interface -->
            <mat-expansion-panel #newlayer>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Create New Layer
                    </mat-panel-title>
                    <mat-panel-description>
                        Create a new empty layer
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- Domain buttons for latest version -->
                <table class="btn-group" role="group">
                    <ng-template ngFor let-domain [ngForOf]="filterDomains(ds.versions[0])" let-domainIndex="index">
                        <td>
                            <button mat-raised-button (click)="newLayer(domain.id)">{{domain.name}}</button>
                        </td>
                    </ng-template>
                </table>

                <!-- More domain/version options -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            More Options
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <table class="inputTable">
                        <tr>
                            <!-- Load data from different version -->
                            <td class="width-lg">
                                <!-- Choose version -->
                                <mat-form-field>
                                    <mat-select placeholder="Choose a version" [(ngModel)]="nVersion" required>
                                        <mat-option [value]="null">none</mat-option>
                                        <mat-option *ngFor="let nVersion of this.dataService.versions"
                                                    [value]="nVersion">{{nVersion.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- Choose domain -->
                                <mat-form-field>
                                    <mat-select placeholder="Choose a domain" [(ngModel)]="nDomain" [disabled]="!nVersion"
                                                required>
                                        <mat-option [value]="null">none</mat-option>
                                        <mat-option *ngFor="let nDomain of filterDomains(nVersion)"
                                                    [value]=nDomain>{{nDomain.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <em>* Official ATT&CK Versions prior to {{ds.lowestSupportedVersion.name}} are not supported by Navigator v{{nav_version}}.</em>
                                </div>
                            </td>

                            <td class="width-sm"><b style="font-size: 8pt">OR</b></td>

                            <!-- Load data from collection or STIX bundle URL -->
                            <td class="width-lg">
                                <mat-form-field>
                                    <input type="url" matInput placeholder="Collection or STIX bundle URL" [(ngModel)]="loadData.url"/>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" matInput placeholder="Version number" [(ngModel)]="loadData.version"/>
                                    <mat-hint>ATT&CK version (e.g. 12)</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Domain" [(ngModel)]="loadData.identifier"/>
                                    <mat-hint>Domain identifier (e.g. defending-iaas)</mat-hint>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="width-lg">
                                <button style="margin-top: 20px" mat-button [disabled]="!nVersion || !nDomain" (click)="newLayer(nDomain.id)">
                                    Create layer from Version
                                </button>
                            </td>
                            <td class="width-sm"></td>
                            <td class="width-lg">
                                <button style="margin-top: 20px" mat-button [disabled]="!loadData.url || !loadData.version || !loadData.identifier" (click)="newLayerFromURL(loadData)">
                                    Create layer from URL
                                </button>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel>
            </mat-expansion-panel>

            <!-- Open existing layer interface -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Open Existing Layer
                    </mat-panel-title>
                    <mat-panel-description>
                        Load a layer from your computer or a URL
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <table class="inputTable">
                    <tr>
                        <!-- Upload file from local -->
                        <td>
                            <button mat-button (click)="openUploadPrompt()">Upload from local</button>
                            <input id="uploader" type="file" style="display: none" (change)="loadLayerFromFile()"/></td>

                        <td><b style="font-size: 8pt">OR</b></td>

                        <!-- Upload layer via URL -->
                        <td>
                            <mat-form-field>
                                <input type="text"
                                       matInput
                                       placeholder="Load from URL"
                                       [(ngModel)]="loadURL"/>
                                <button mat-button matSuffix mat-icon-button aria-label="go" *ngIf="loadURL"
                                        (click)="loadLayerFromURL(loadURL, true)">>
                                </button>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </mat-expansion-panel>

            <!-- Create layer from other layers -->
            <mat-expansion-panel (opened)="activeTab.showScoreVariables = true"
                                 (closed)="activeTab.showScoreVariables = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Create Layer from other layers
                    </mat-panel-title>
                    <mat-panel-description>
                        Choose layers to inherit properties from
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <table class="layerOpTable">
                    <!-- Domain -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="domain" [(ngModel)]="domain" required>
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let domain of this.dataService.domains"
                                                [value]=domain.id>{{domain.name}} {{domain.version.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            Choose the domain and version for the new layer. Only layers of the same domain and version
                            can be merged.
                        </td>
                    </tr>

                    <!-- Score expression -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <input type="text"
                                       matInput
                                       placeholder="score expression"
                                       [disabled]="!domain"
                                       [(ngModel)]="scoreExpression"
                                       (keyup)="scoreExpression = scoreExpression.toLowerCase()"
                                       style="letter-spacing: 2px"/>
                                <mat-hint style="color: red" align="start">{{getScoreExpressionError()}}</mat-hint>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Use constants (numbers) and layer variables (yellow, above) to write an expression for the
                            initial value of scores in the new layer. A full list of supported operations can be found
                            <a href="http://mathjs.org/docs/expressions/syntax.html#operators">here</a>. Leave blank to
                            initialize scores to 0. Here's a list of available layer variables:
                            <ul *ngFor="let tab of layerTabs; let $i = index">
                                <li *ngIf="getActiveTab().showScoreVariables && tab.isDataTable && (!domain || tab.domain === domain) && alphabetical(indexToChar($i))"><span class="tab-enumerator">{{indexToChar($i)}}</span> ({{tab.title}})</li>
                            </ul>
                        </td>
                    </tr>

                    <!-- Gradient -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="gradient" [(ngModel)]="gradient" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import the scoring gradient from. Leave blank to initialize with the
                            default scoring gradient.
                        </td>
                    </tr>

                    <!-- Coloring -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="coloring" [(ngModel)]="coloring" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import manually assigned colors from. Leave blank to initialize with
                            no colors.
                        </td>
                    </tr>

                    <!-- Comments -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="comments" [(ngModel)]="comments" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import comments from. Leave blank to initialize with no comments.
                        </td>
                    </tr>

                    <!-- Links -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="links" [(ngModel)]="links" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import technique links from. Leave blank to initialize without links.
                        </td>
                    </tr>

                    <!-- Metadata -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="metadata" [(ngModel)]="metadata" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import technique metadata from. Leave blank to initialize without metadata.
                        </td>
                    </tr>

                    <!-- States -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="states" [(ngModel)]="enabledness" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import enabled/disabled states from. Leave blank to initialize all to
                            enabled.
                        </td>
                    </tr>

                    <!-- Filters -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="filters" [(ngModel)]="filters" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import filters from. Leave blank to initialize with no filters.
                        </td>
                    </tr>

                    <!-- Legend -->
                    <tr>
                        <td>
                            <mat-form-field>
                                <mat-select placeholder="legend" [(ngModel)]="legendItems" [disabled]="!domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getLayers()" [value]=vm>{{vm.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td [ngClass]="{disabled: !domain}">
                            Choose which layer to import the legend from. Leave blank to initialize with an empty
                            legend.
                        </td>
                    </tr>
                </table>

                <!-- Create -->
                <button mat-button [disabled]="getScoreExpressionError() || !domain"
                        (click)="layerByOperation(); showScoreVariables = false">Create
                </button>
            </mat-expansion-panel>

            <!-- Customized Navigator-->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Create Customized Navigator
                    </mat-panel-title>
                    <mat-panel-description>
                        Create a hyperlink to a customized ATT&CK Navigator
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <table class="inputTable">
                    <!-- Default layers -->
                    <tr>
                        <td><h1>Default Layers</h1></td>
                        <td></td>
                    </tr>
                    <tr class="custom_nav_inputsection">
                        <td>
                            <ul class="layerLinks">
                                <li *ngFor="let layerLinkURL of layerLinkURLs; let i = index trackBy:trackByFunction">
                                    <mat-form-field>
                                        <input type="text" matInput
                                               [placeholder]="'default layer URL ' + (i + 1)"
                                               [(ngModel)]="layerLinkURLs[i]"/>
                                        <button mat-button matSuffix mat-icon-button aria-label="remove"
                                                (click)="removeLayerLink(i)">
                                            x
                                        </button>
                                    </mat-form-field>
                                </li>
                                <li>
                                    <button mat-button (click)="addLayerLink()">
                                        add {{layerLinkURLs.length > 0 ? "another" : "a"}} layer link
                                    </button>
                                </li>
                            </ul>
                        </td>
                        <td>Enter the URLs of layers hosted on the web. The custom navigator will open these layers by
                            default.
                        </td>
                    </tr>

                    <!-- Navigator Features -->
                    <tr>
                        <td><h1>Navigator Features</h1></td>
                        <td></td>
                    </tr>
                    <ng-template ngFor let-featureItem [ngForOf]="customizedConfig" let-featureItemIndex="index">
                        <!-- features -->
                        <tr class="featureRow noselect" *ngIf="!featureItem.subfeatures"
                            (click)="featureItem.enabled = !featureItem.enabled">
                            <td class="featureRow-button">
                                <ng-template [ngIf]="featureItem.enabled">
                                    <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                    </svg>
                                </ng-template>
                                <ng-template [ngIf]="!featureItem.enabled">
                                    <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                </ng-template>
                                {{featureItem.name.split("_").join(" ")}}
                            </td>
                            <td>{{featureItem.description}}</td>
                        </tr>
                        <!-- sub-featuers -->
                        <tr *ngIf="featureItem.subfeatures">
                            <td>
                                <h2>{{featureItem.name.split("_").join(" ")}}</h2>
                            </td>
                            <td></td>
                        </tr>
                        <ng-template ngFor let-subfeature [ngForOf]="featureItem.subfeatures"
                                     let-subfeatureIndex="index" let-last="last">
                            <tr class="featureRow noselect" (click)="subfeature.enabled = !subfeature.enabled">
                                <td class="featureRow-button subfeature" [class.last]="last" style="padding-left: 20px">
                                    <ng-template [ngIf]="subfeature.enabled">
                                        <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                        </svg>
                                    </ng-template>
                                    <ng-template [ngIf]="!subfeature.enabled">
                                        <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </ng-template>
                                    {{subfeature.name.split("_").join(" ")}}
                                </td>
                                <td class="subfeature" [class.last]="last">{{subfeature.description}}</td>
                            </tr>
                        </ng-template>
                    </ng-template>
                </table>

                <!-- Custom Navigator URL -->
                <div style="text-align: center; margin-top: 20px">
                    <mat-form-field id="layerlinkfield">
                        <input id="layerLink" type="text"
                               matInput
                               readonly="readonly"
                               (click)="selectLayerLink()"
                               [value]="getLayerLink()"
                               placeholder="custom navigator URL"/>
                        <button (click)="copyLayerLink()" mat-button matSuffix mat-icon-button aria-label="copy"><span class="layerLinkCopy"
                                matTooltip="copy" matTooltipPosition="right">
                                <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                </svg>
                            </span>
                        </button>
                        <mat-hint *ngIf="copiedRecently">copied</mat-hint>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</ng-template>

<ng-template [ngIf]="activeTab && activeTab.isDataTable">
    <DataTable [viewModel]="activeTab.dataContext" [currentDropdown]="dropdownEnabled" (dropdownChange)="dropdownEnabled = $event" (onScroll)="adjustHeader($event)"></DataTable>
</ng-template>
